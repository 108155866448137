<template>
  <div class="balance">
    <div class="new-campaign">
      <div class="container">
        <div class="header-wrap">
          <h2>{{ $t("balance.addTitle") }}</h2>
        </div>

        <div class="creation-wrap">
          <form @submit="create_payment_customer" class="form" method="post">
            <div v-if="payment_customers.length > 0">
              <div class="creation-block" style="margin: 0">
                <h3>{{ $t("balance.adress") }}</h3>
                <div class="form-wrap">
                  <select v-model="payment_method.customer_uuid" class="form-input">
                    <option v-for="customer in payment_customers" v-bind:key="customer.uuid" v-bind:value="customer.uuid">
                      {{ customer.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="creation-block" style="margin: 0">
                <h3>{{ $t("balance.select") }}</h3>
                <div class="form-wrap">
                  <select v-model="payment_method.payment_method" class="form-input">
                    <option value="directdebit">{{ $t("balance.sepa") }}</option>
                    <option value="creditcard">{{ $t("balance.creditcard") }}</option>
                  </select>
                </div>
              </div>

              <div v-if="payment_method.payment_method == 'directdebit'" class="creation-block" style="margin: 0">
                <h3>{{ $t("balance.addInfo") }}</h3>
                <div class="form-wrap">
                  <input class="form-input" type="text" value="John Doe" name="name" id="name" placeholder="Name" />
                </div>
                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap" style="margin: 0">
                      <input class="form-input" value="NL08ZZZ502057730000" type="text" name="name" id="name" placeholder="IBAN" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap" style="margin: 0">
                      <input class="form-input" type="text" value="INGBNL2A" name="name" id="name" placeholder="BIC" />
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="payment_method.payment_method == 'creditcard'" class="alert alert-info">
                {{ $t("balance.redirect") }}
              </div>

              {{ errors }}

              <p style="margin-bottom: 0">
                <input type="submit" value="Weiter zu Schritt 2 ›" class="button button-ci button-round button-100" />
              </p>
            </div>
            <div v-else>
              <div style="text-align: center">
                <p>{{ $t("balance.emptyPayment") }}</p>
                <p>
                  <router-link to="/balance/customers/new" class="button button-ci button-round">
                    <img src="../../../assets/icons/plus.svg" />
                    <span>{{ $t("balance.addAdress") }}</span>
                  </router-link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";

export default {
  name: "new_balance_customer",
  data() {
    return {
      errors: [],
      user: {},
      payment_customers: [],
      payment_method: {
        customer_uuid: "",
        payment_method: "",
      },
    };
  },
  methods: {
    get_user() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        });
    },
    get_payment_customers() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user/payment_customers", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.payment_customers = response.data.payment_customers;
        });
    },
    create_payment_customer: function (e) {
      e.preventDefault();

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/user/payment_methods",
          {
            customer_uuid: this.payment_method.customer_uuid,
            payment_method: this.payment_method.payment_method,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          console.log(this.payment_method);
          if (this.payment_method.payment_method == "creditcard") {
            console.log(response.data.payment_mandate.checkout_url);
            window.location = response.data.payment_mandate.checkout_url;
          } else {
            this.$router.push("/balance");
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.get_user();
    this.get_payment_customers();
  },
};
</script>

<style>
.content-wrap {
  min-height: 200px;
}

p.balance {
  margin: 0;
  font-size: 30px;
}
</style>
